
.mapStyle{
  position:absolute; 
  top:65px; 
  bottom:0px; 
  width: '80vw';
   height: '80vh';
  }
  
  body { 
  margin:0; 
  padding:0;
  }


  .bump-marker {
    background: none;
    border: none;
  }
  
  .bump-marker img {
    width: 25px;
  }


  .cluster-marker {
    color: #fff;
    background: #1978c8;
    border-radius: 50%;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  